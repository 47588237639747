<template>
  <v-container
      id="register"
      class="fill-height justify-center"
      tag="section"
  >
    <Overlay :overlay="globalLoading" :color="'primary'"></Overlay>

    <v-row justify="center" v-show="!globalLoading">
      <v-col cols="12" class="text-center">
        <h1>
          <span class="text-h1">M360API</span> &reg;<br/>
          <span class="text-h2">API Management Platform</span>
        </h1>
      </v-col>
    </v-row>

    <Messages :messages="globalMessages"></Messages>

    <v-row justify="center" v-show="!globalLoading">
      <v-slide-y-transition appear>
        <base-material-card
            v-if="!login3rdParty"
            color="success"
            light
            max-width="100%"
            width="800"
            class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Register
              </h1>
            </div>
          </template>
          <form @submit="checkForm" novalidate="true">
            <v-card-text class="text-center">

              <v-text-field
                  color="secondary"
                  label="First Name..."
                  prepend-icon="mdi-face"
                  v-model="firstName"
              />

              <v-text-field
                  color="secondary"
                  label="Last name..."
                  prepend-icon="mdi-card-account-details"
                  v-model="lastName"
              />

              <v-text-field
                  color="secondary"
                  label="Email..."
                  prepend-icon="mdi-email"
                  v-model="email"
              />

              <v-text-field
                  color="secondary"
                  label="Company Name..."
                  prepend-icon="mdi-domain"
                  v-model="companyName"
              />

              <div>
                <v-checkbox v-model="agreeTerms">
                  <template v-slot:label>
                    <div>
                      I agree to the
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a target="_blank"
                             href="https://www.m360api.io/terms-of-service"
                             @click.stop
                             v-on="on">Terms of Service</a>
                        </template>
                        Terms of Service
                      </v-tooltip>
                      and
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a target="_blank"
                             href="https://www.m360api.io/privacy-policy"
                             @click.stop
                             v-on="on">Privacy Policy</a>
                        </template>
                        Privacy Policy
                      </v-tooltip>
                      .
                    </div>
                  </template>
                </v-checkbox>
              </div>
              <pages-btn
                  medium
                  color=""
                  depressed
                  class="mr-1 v-btn--text success--text"
                  @click="checkForm"
              >
                <v-icon class="mr-1">mdi-account-multiple-plus</v-icon>
                Register
              </pages-btn>
            </v-card-text>
          </form>

          <div class="my-2"/>

          <div class="or-element text-center grey--text body-1 font-weight-light pa-3 mb-2">
            <span>OR</span>
          </div>
          <v-row class="ma-1 pa-1">

            <v-col sm="12" xs="12" md="12" class="pa-0 ma-0 text-center" v-for="(oneStrategy, name) in strategies"
                   :key="name">
              <pages-btn
                  class="mb-2"
                  medium
                  color=""
                  depressed
                  :class="'mr-1 v-btn--text ' + oneStrategy.color + '--text'"
                  @click="login3rdParty = name"
              >
                <v-icon class="mr-1">mdi-{{ oneStrategy.icon }}</v-icon>
                Sign Up with {{ name }}
              </pages-btn>
            </v-col>
          </v-row>
        </base-material-card>
        <base-material-card
            v-else
            color="success"
            light
            max-width="100%"
            width="500"
            class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2 ">
                Signing in with <span class="text-capitalize">{{ login3rdParty }}</span>
              </h1>
            </div>
          </template>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      color="secondary"
                      label="Company Name..."
                      prepend-icon="mdi-domain"
                      v-model="companyName"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div>
                    <v-checkbox v-model="agreeTerms">
                      <template v-slot:label>
                        <div>
                          I agree to the
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a target="_blank"
                                 href="https://www.m360api.io/terms-of-service/"
                                 @click.stop
                                 v-on="on">Terms of Service</a>
                            </template>
                            Terms of Service
                          </v-tooltip>
                          and
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a target="_blank"
                                 href="https://www.m360api.io/privacy-policy"
                                 @click.stop
                                 v-on="on">Privacy Policy</a>
                            </template>
                            Privacy Policy
                          </v-tooltip>
                          .
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>

              <pages-btn
                  medium
                  color=""
                  depressed
                  :class="'mr-1 v-btn--text ' + strategies[login3rdParty].color + '--text'"
                  @click="startLogin3rdParty"
              >
                <v-icon class="mr-1">mdi-{{ strategies[login3rdParty].icon }}</v-icon>
                Proceed
              </pages-btn>
              <pages-btn
                  medium
                  color=""
                  depressed
                  class="mr-1 v-btn--text error--text"
                  @click="login3rdParty =false"
              >
                <v-icon class="mr-1">mdi-close</v-icon>
                Cancel
              </pages-btn>
            </v-card-actions>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>

  </v-container>
</template>

<script>
import globalMixins from "../../mixins/globalMixins";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Register',

  mixins: [globalMixins],

  components: {
    Overlay: () => import('../../components/OverlayLoading'),
    Messages: () => import('../../components/Messages'),
    PagesBtn: () => import('./components/Btn')
  },

  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    agreeTerms: false,
    login3rdParty: false,
    strategies: {
      google: {
        color: 'success',
        icon: 'google-plus',
        key: 'db019d15552f7dc8d64d774967fbdfdeb3bdc0a7d61fe0af1274075fa2235c0faf71aa842b4274acbe8e84085a1702ed4a7ceb08c6a129d57882481d9ff708a6b1e44c317eb034549eb2db013522aa8c'
      },
      // facebook: {
      //   color: 'primary',
      //   icon: 'facebook',
      //   key: '7ee522eaca139a341db9eca7c86120c1231a7b3c094b129976ccc8d1b674cf559b892ea03cc3ce72bbbb83b72e75a73b7d25950433aef6a6bd98ecedd5103d9d42d6021e633c8469d7fc00937e222092'
      // },
      // linkedin: {
      //   color: 'info',
      //   icon: 'linkedin',
      //   key: '364cdfccc75ed2df8a1e94087efeafd5119abdfe7ba9acb92fd9ea71b90f7bef7285edb9de03352373158be8d8b79ebb8c3da95ba950f2f76859790e8922f8a0e52373162c48f5cf6dc386a564f661e4'
      // }
    }
  }),

  created() {
    this.clearMessages();
  },

  methods: {
    ...mapActions('auth', ['setToken']),

    clearForm() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.companyName = '';
    },

    async checkForm(e) {
      if (!this.agreeTerms) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'You must agree to the Terms of Service and to the Privacy Policy so you can proceed.'
        });
        return false;
      }

      if (!this.email || !this.validUserEmail(this.email)) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a valid email address.'
        });
        return false;
      }

      if (!this.firstName) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide your first name.'
        });
        return false;
      }

      if (!this.lastName) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide your last name.'
        });
        return false;
      }

      if (!this.companyName) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide your company\'s name.'
        });
        return false;
      }

      await this.submit();
      e.preventDefault();
    },

    /* eslint-disable */
    validUserEmail: function (email) {
      var re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
      return re.test(email);
    },

    /* eslint-enable */
    submit() {
      const self = this;
      this.clearMessages();
      return self.executeRequest({
        url: '/register',
        method: 'post',
        headers: {
          env: 'portal'
        },
        params: {
          "firstName": this.firstName,
          "lastName": this.lastName,
          "email": this.email,
          "companyName": this.companyName,
          "auto_register_flow": "corsair_saas"
        }
      }).then(response => {

        this.pushMessage({
          'type': 'success',
          'title': `Account Registered`,
          'text': 'Thank you for registering. We sent you an email that contains a link. Please click on that link to validate your registration and proceed.'
        });
        this.clearForm();
      }).catch(error => {
        self.handleErrorResponse(error);
      });
    },

    startLogin3rdParty() {
      const self = this;

      if (!this.agreeTerms) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'You must agree to the Terms of Service and to the Privacy Policy so you can proceed.'
        });
        return false;
      }

      if (!this.companyName) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide your company\'s name.'
        });
        return false;
      }

      return self.executeRequest({
        url: `/oauth/${self.login3rdParty}`,
        method: 'post',
        headers: {
          env: 'portal',
          'x-key': self.strategies[self.login3rdParty].key
        },
        params: {
          companyName: this.companyName,
          "auto_register_flow": "corsair_saas",
          "return_to_page": window.location.origin + "/social/" + self.login3rdParty
        }
      }).then(response => {
        window.location.href = response;
      }).catch(error => {
        self.handleErrorResponse(error);
      });
    }

  }
}
</script>
<style>
.or-element {
  position: relative;
}

.or-element::before {
  display: inline-block;
  border-top: 1px solid rgb(184, 196, 194);
  content: "";
  margin: 0 auto;
  position: absolute;
  height: 0;
  inset: 50% 0 0;
  z-index: 0;
  transform: translateY(-50%);
  width: 300px;
}

.or-element span {
  background-color: #FFFFFF;
  padding: 0 10px;
  position: relative;
  top: 2px;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
}
</style>